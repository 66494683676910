import React from 'react';
import '../App.css';

function Footer() {
  const currentYear = new Date().getFullYear(); // Get current year

  return (
    <footer className="footer">
      
      <p>© Propertunity {currentYear}</p>
    </footer>
  );
}

export default Footer;
