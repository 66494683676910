import React from 'react';



function HomePage() {  
    return (
      <section className="hero">
        <div className="content">
        <div className="text-background">
          <h1>Distressed Real Estate.</h1>
          <p>
            {" "}
            We do the hard work so you don't have to.
          </p>
          </div>
          </div>
      </section>
    );

}

export default HomePage;
