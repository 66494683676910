import React from 'react'

function Slide4() {
  return (
    <section className="hero3">
    <div className="content">
    <div className="text-background">
      <h1>Our Impact</h1>
      <p>
        Our community footprint increases value to our homeowner clients as well as to investor partners.
      </p>
      <button onClick={() => {}}>Our Owner Support</button>
    </div>
    </div>
  </section>
);

}

export default Slide4