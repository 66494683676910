import React from 'react'

function Community() {
  return (
    <section className="hero8">
    <div className="content">
    <div className="text-background">
      <h1>Community Healing</h1>
      <p>
      Distressed properties reflect on the whole neighborhood. We put our energy towards making the healing faster, more efficient and we return the value back into the community.
      </p>
      
    </div>
    </div>
  </section>
  )
}

export default Community