import React from 'react'

function Slide3() {
  return (
    <section className="hero2">
    <div className="content">
    <div className="text-background">
      <h1>Our Process</h1>
      <p>
        Our process mastery is second to none. We employ the most advanced tactics in wealth management in real estate.
      </p>
      </div>
    </div>
  </section>
);

}

export default Slide3