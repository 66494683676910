import React from 'react'

function Slide2() {
  return (
    <section className="hero1">
    <div className="content">
    <div className="text-background">
      <h1>Our Approach</h1>
      <p>
       We seek partnerships underpinned in trust and credibility, but backed by our securities.
      </p>
      
    </div>
    </div>
  </section>
);

}

export default Slide2