import React from 'react'

function Investments() {
  return (
    
    <section className="hero6">
    <div className="content">
    <div className="text-background">
      <h1>Investment Strategy</h1>
      <p>
      We focus on acquiring distressed properties at the best possible prices, leveraging market opportunities to maximize returns. By targeting undervalued assets and utilizing strategic rehabilitation, we secure exceptional investment outcomes while minimizing risk through a disciplined acquisition approach.
      </p>
      
    </div>
    </div>
  </section>
  )
}

export default Investments