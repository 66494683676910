import React from 'react'

function Owners() {
  return (
    <section className="hero7">
    <div className="content">
    <div className="text-background">
      <h1>We Work With Owners</h1>
      <p>
     We employ many financing offers to ensure our owners get loan approvals, and move into their homes comfortably.
      </p>
      
    </div>
    </div>
  </section>
  )
}

export default Owners