import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logoBase64 } from './logo'; // Assuming you have this imported

function Navbar() {
  const [showSubLinks, setShowSubLinks] = useState({
    section1: false,
    section2: false,
  });

  const handleToggleSubLinks = (section) => {
    setShowSubLinks((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <nav className="header">
      <div className="logo-container">
      <a href="/" className="logo-link">
        <img
          src={logoBase64} // Use the logoBase64 variable here
          alt="Propertunity Logo"
          className="logo"
        />
        <span className="company-name">Propertunity</span>
        </a>
      </div>
      <div className="nav-links">
      <div className="nav-item" onClick={() => handleToggleSubLinks('section1')}>
            <Link >
              Our Firm
              <span
                className={`dropdown-icon ${showSubLinks.section1 ? 'rotated' : ''}`}
              ></span>
            </Link>
            {showSubLinks.section1 && (
              <div className="sub-links">
                <Link to="/about-us">About Propertunity</Link>
                <Link to="/our-people">Our People</Link>
                <Link to="/contact-us">Contact</Link>
              </div>
            )}
          </div>

        <div className="nav-item" onClick={() => handleToggleSubLinks('section2')}>
          <Link >
            Our Business
            <span
              className={`dropdown-icon ${showSubLinks.section2 ? 'rotated' : ''}`}
            ></span>
          </Link>
          {showSubLinks.section2 && (
            <div className="sub-links">
              <Link to="/equity">Equity</Link>
              <Link to="/bonds">Fixed Income</Link>
              <Link to="/investments">Investments</Link>
            </div>
          )}
        </div>

        {/* Our Impact dropdown */}
        <div className="nav-item" onClick={() => handleToggleSubLinks('section4')}>
          <Link >
            Our Impact
            <span
              className={`dropdown-icon ${showSubLinks.section4 ? 'rotated' : ''}`}
            ></span>
          </Link>
          {showSubLinks.section4 && (
            <div className="sub-links">
              <Link to="/community">Community</Link>
              <Link to="/owners">Owners</Link>
            </div>
          )}
        </div>

        <Link to="#">Investor Login</Link>
        {/* <Link to="/compliance">Compliance</Link> */}
      </div>
    </nav>
  );
}

export default Navbar;
