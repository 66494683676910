import React from 'react'

function Bonds() {
    return (
      <section className="hero5">
      <div className="content">
      <div className="text-background">
        <h1>High Yield Fixed Income</h1>
        <p>
          Our most popular program is our securities based bond portfolio. High yields, dependable, and underwritten by our property portfolio.
        </p>
        
      </div>
      </div>
    </section>
  );
}

export default Bonds