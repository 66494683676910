import React from 'react'

function AboutUs() {
  return (
    <section className="hero1">
    <div className="content">
    <div className="text-background">
      <h1>About Us</h1>
      <p>
        Propertunity was born out of a deep longing for improving communities and being a part of the home ownership solution for all levels of the society. We pride ourselves in only making deals that are win-win for our investors and for the community.
      </p>
    </div>
    </div>
  </section>
);

}

export default AboutUs