import React, { useState } from 'react';
import LarisaImage from '../image/LarisaWebProfilePic.jpg';
import MichaelaImage from '../image/MichaelaProfile.jpg';
import AnotherProfileImage from '../image/BlankProfileBorder.jpg'; 

function OurPeople() {
  // State to track which profile is expanded
  const [expandedProfile, setExpandedProfile] = useState(null);

  // Sample profile data based on your images
  const profiles = [
    {
      name: 'Larisa Mathur',
      title: 'Head of Corporate Private Equity',
      image: LarisaImage,
      description: 'Larisa is a highly skilled professional with a diverse background in real estate, investment advisory, and industrial engineering. As a certified real estate broker and investment advisor with a Series 65 certification, she has the expertise to navigate complex financial landscapes and provide strategic investment guidance. Additionally, her industrial engineering background allows her to specialize in process optimization, enabling her to design and implement systems that drive efficiency and performance. With extensive experience in developing intricate systems that support business operations and wealth management products, Larisa plays a pivotal role in overseeing key areas such as operations, strategy, investor relations, and compliance. Her comprehensive skill set positions her as a leader capable of blending technical proficiency with strategic oversight to foster business growth and ensure regulatory adherence.',
    },
    {
      name: 'Michaela Rudis',
      title: 'Head of Sales',
      image: MichaelaImage,
      description: 'Michaela is a seasoned real estate broker with over 20 years of experience in residential sales, making her a trusted expert in the industry. Her extensive career has honed her skills in advertising and relationship development, allowing her to create impactful marketing campaigns that attract buyers and foster strong client connections. Michaela is instrumental in property positioning, utilizing her deep understanding of market trends to present homes in the most appealing way possible. Additionally, she leads the go-to-market strategy, ensuring properties are effectively launched and promoted to maximize visibility and attract potential buyers. With her wealth of experience and strategic vision, Michaela continues to deliver exceptional results for her clients.',
    },
    {
      name: 'Open', 
      title: 'Corporate Counsil', 
      image: AnotherProfileImage, 
      description: 'Ideal candidate loves real estate and securities law',
    },
  ];

  // Function to toggle profile expansion
  const toggleProfile = (index) => {
    setExpandedProfile(expandedProfile === index ? null : index);
  };

  return (
    <>
      {/* Hero Section */}
      <section className="hero3">
        <div className="content">
          <div className="text-background">
            <h1>Our People</h1>
            <p>Meet The Team</p>
          </div>
        </div>
      </section>
      <div className="spacer"></div>
    
      <section className="profiles-section">
        <div className="profiles-frame">
          {profiles.map((profile, index) => (
            <div key={index} className="profile" onClick={() => toggleProfile(index)}>
              <img src={profile.image} alt={profile.name} />
              <h2>{profile.name}</h2>
              <p>{profile.title}</p>

              {expandedProfile === index && (
                <div className="expanded-profile">
                  <p>{profile.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <div className="spacer"></div>
    </>
  );
}

export default OurPeople;
